import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/icons
import Phone from "@material-ui/icons/Phone";
import WhatsApp from "@material-ui/icons/WhatsApp";
import Report from "@material-ui/icons/Report";
import Mail from "@material-ui/icons/MailOutline";
import Place from "@material-ui/icons/Place";
import Card from "components/Card/Card.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import transparente from "assets/img/transparent.png";

import GoogleMapReact from 'google-map-react'

import { Icon } from 'react'
import locationIcon from '@material-ui/icons/Place'

const useStyles = makeStyles(styles);

const langData = [
  {
    id: "es",
    name: "Castellano",
    contact: "Contacto",
    mail: "Solicite un servicio o presupuesto por correo electrónico en cualquier momento y te contestaremos lo antes posible",
    see: "Le atenderemos en Port Ginesta local 713, 08860 Castelldefels (Barcelona) de 9:00 a 13:00 y de 15:00 a 18:00 de lunes a sábado",
    call: "Llámenos, le atenderemos de 8:00 a 18:00 de lunes a sábado",
    emergency: "En caso de urgencia"
  },
  {
    id: "ca",
    name: "Català",
    contact: "Contacte",
    mail: "Demaneu un servei o pressupost per correu electrònic en qualsevol moment i et contestarem el més aviat possible",
    see: "L'atendrem a Port Ginesta local 713, 08860 Castelldefels (Barcelona) de 9:00 a 13:00 i de 15:00 a 18:00 de dilluns a dissabte",
    call: "Truqui'ns, l'atendrem de 8:00 a 18:00 de dilluns a dissabte",
    emergency: "En cas d'urgència"
  },
  {
    id: "en",
    name: "English",
    contact: "Contact",
    mail: "Request a service or quote by email at any time and we will get back to you as soon as possible",
    see: "We will attend you in Port Ginesta local 713, 08860 Castelldefels (Barcelona) from 9:00 to 13:00 and from 15:00 to 18:00 from Monday to Saturday",
    call: "Call us, we will attend you from 8:00 to 18:00 from Monday to Saturday",
    emergency: "In case of emergency"
  },
];

const location = {
  address: 'Sugamar, Port Ginesta.',
  lat: 41.26099277239686,
  lng: 1.925871943933259,
}
const zoomLevel = 12

const AnyReactComponent = ({ text }) => (
  <div style={{
    position: 'absolute',
    width: 20,
    height: 20,
    left: -20 / 2,
    top: -20 / 2,

    border: '7px solid #f44336',
    borderRadius: 20,
    backgroundColor: 'white',
    textAlign: 'left',
    color: '#3f51b5',
    fontSize: 16,
    fontWeight: 'bold',
    padding: 5
  }}>
  </div>
);




export default function ContactSection(props) {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const idioma=langData.find(p => p.id === props.lang);  
  return (
    <div className={classes.section} id="contacto">
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>{idioma.contact}</h2>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={5}>
            <InfoArea
              title="sugamarnautica@gmail.com"
              description={idioma.mail}
              icon={Mail}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
              <GridContainer direction="column" justify="flex-start" alignItems="flex-start
              ">
                <GridItem xs={12} sm={12} md={10}>
                  <InfoArea
                    title="93 684 30 73"
                    description=""
                    icon={Phone}
                    iconColor="info"
                    info-horizontal
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={10}>
                  <InfoArea
                    title="669 74 63 37"
                    description={idioma.WhatsApp}
                    icon={WhatsApp}
                    iconColor="success"
                    info-horizontal
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={10}>
                  <InfoArea
                    title="639 85 37 79"
                    description={idioma.emergency}
                    icon={Report}
                    iconColor="danger"
                    info-horizontal
                  />
                </GridItem>
              </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Port Ginesta, Castelldefels"
              description={idioma.see}
              icon={Place}
              iconColor="danger"
              vertical
            />
          </GridItem>
          
        
        </GridContainer>
        <GridContainer>

          <GridItem xs={12} sm={12} md={12}>
            
              <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyDpyP5Yg-Dh5JdsLSts9CQDZN3sCBzvYco' }}
                defaultCenter={location}
                defaultZoom={zoomLevel}
              >
                <AnyReactComponent
                  lat={location.lat}
                  lng={location.lng}
                  text={location.address}
                />
              </GoogleMapReact>
              <Card plain>
              <GridItem xs={12} sm={12} md={4} className={classes.itemGrid}>
                <img src={transparente} alt="..." className={imageClasses} />
              </GridItem>
            </Card>
          </GridItem>
        </GridContainer>

      </div>

    </div>
  );
}
